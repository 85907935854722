<template>
  <div>
    <dashboard-page-title :showAddBtn="false" :showFilter="true" :showSearch="true" :showExportation="true">شركات التامين</dashboard-page-title>

    <main-table :baseURL="VUE_APP_INSURANCE_LINK"  :fields="fields" list_url="insuranceServiceProvider"></main-table>
  </div>
</template>

<script>
import { VUE_APP_INSURANCE_LINK } from '@/config/constant'

export default {
  data () {
    return {
      VUE_APP_INSURANCE_LINK,
      matone: {
        matoneNumber: 1,
        value: 500000,
        mount: 20,
        minmount: 300,
        certificate: 24,
        tax: 15,
        prices: [
          { from: 0, to: '', precent: '', disabled: true, error: '' }
        ]
      },
      fields: [
        { label: 'شعار الشركة', key: 'image', type: 'image', class: 'text-right' },
        { label: 'أقصى عدد للمتون', key: '5', class: 'text-right' },
        { label: 'أقصى قيمة للمتون', key: '300', class: 'text-right' },
        // { label: 'ضريبة القيمة المضافة', key: 'insuraceServiceProvider', class: 'text-right' },
        { label: 'الحد الأدنى لقيمة التأمين', key: 'options.min_mount', class: 'text-right' },
        { label: 'الحالة', key: 'change_status', class: 'text-right', type: 'changeStatus' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addNewRow () {
      this.matone.prices.push({ from: '', to: '', precent: '' })
    },
    decrease () {
      if (this.matone.matoneNumber > 1) {
        this.matone.matoneNumber--
      }
    }
  }
}
</script>
